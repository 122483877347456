import React from 'react';
import { Link } from 'gatsby';

const Header: React.FC = () => (
    <header className="container mx-auto pt-6 flex justify-center lg:justify-between flex-col lg:flex-row">
        <h1 className="text-2xl lg:text-4xl text-center w-full lg:w-auto">
            <Link to="/">Pierre Buzulier</Link>
        </h1>
        <nav className="flex w-full lg:w-auto flex-row justify-around mt-5 lg:mt-0">
            <Link to="/" className="mr-6 flex items-end">
                work
            </Link>
            <Link to="/books" className="mx-6 flex items-end">
                books
            </Link>
            <Link to="/contact" className="ml-6 flex items-end">
                contact
            </Link>
        </nav>
    </header>
);

export default Header;
