import React, { FormEvent, useState } from 'react';
import useSecured from 'hooks/secured';

const isBrowser = typeof window !== 'undefined';

const Password: React.FC = () => {
    const [password, setPassword] = useState<string | null>(null);
    const { setAuthentication } = useSecured('');
    const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const isValid = setAuthentication(password ?? '');

        if (isValid && isBrowser) {
            window.location.reload();
        }
    };

    return (
        <main className="container mx-auto p-6 min-h-screen relative pb-24">
            <div>
                <form onSubmit={onSubmit} className="grid grid-cols-1 gap-2 py-12 lg:py-24 lg:px-36">
                    Espace sécurisé
                    <input
                        placeholder="Mot de passe"
                        type="text"
                        name="password"
                        id="password"
                        onChange={(event): any => setPassword(event.target.value)}
                    />
                    <button
                        type="submit"
                        className="px-4 py-2 font-semibold text-sm border-black border-2 rounded-full shadow-sm"
                    >
                        Accéder aux travaux
                    </button>
                </form>
            </div>
        </main>
    );
};

export default Password;
