const PATH = 'pierreb';
const PASSWORD = 'PierreWork';
const PUBLIC_PATHS = ['/(\\/?)404(\\/?)/', '/(\\/?)books(\\/?)/', '/(\\/?)contact(\\/?)/'];

type UseSecuredReturn = {
    setAuthentication: (password: string) => boolean;
    shouldAskPassword: () => boolean;
};

const useSecured = (pathname: string): UseSecuredReturn => {
    const isBrowser = typeof window !== 'undefined';
    const { localStorage, alert } = isBrowser ? window : { localStorage: undefined, alert: undefined };
    const setAuthentication = (password: string): boolean => {
        if (password === PASSWORD) {
            localStorage?.setItem(PATH, '1');

            return true;
        }

        alert && alert('Mot de passe invalide');
        return false;
    };

    const isPasswordSet = (): boolean => '1' === localStorage?.getItem(PATH);

    const isProtectedPage = (): boolean => undefined === PUBLIC_PATHS.find((path) => pathname.match(path));

    const shouldAskPassword = (): boolean => isProtectedPage() && !isPasswordSet();

    return {
        setAuthentication,
        shouldAskPassword,
    };
};

export default useSecured;
