import React, { FC, PropsWithChildren } from 'react';
import Helmet from 'react-helmet';
import 'styles/main.css';
import Header from './header';
import useSecured from 'hooks/secured';
import Password from 'components/Password';

const Layout: FC<PropsWithChildren<{ path: string }>> = ({ children, path }) => {
    const { shouldAskPassword } = useSecured(path);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="stylesheet" href="https://use.typekit.net/pqp3ajv.css" />
                <Helmet>
                    <title>Pierre Buzulier - Work</title>
                    <meta name="description" content="Pierre Buzulier - Artistic director" />
                    <meta name="theme-color" content="#FFFFFF" />
                </Helmet>
            </Helmet>
            <Header />
            {shouldAskPassword() ? <Password /> : children}
        </>
    );
};

export default Layout;
